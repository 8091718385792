define("bottomline-execution/serializers/bx/order-remark", ["exports", "active-model-adapter", "ember-uuid"], function (_exports, _activeModelAdapter, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    mapResponse: function mapResponse(orderId, orderRemarks) {
      var mappedOrderRemarks = orderRemarks.map(function (orderRemark) {
        return {
          id: (0, _emberUuid.v4)(),
          type: 'order_remark',
          attributes: {
            OrderID: orderId,
            Remark: orderRemark
          }
        };
      });
      return {
        data: mappedOrderRemarks
      };
    }
  });

  _exports.default = _default;
});