define("bottomline-execution/components/trip-stops", ["exports", "@ember/object/computed", "@ember/array", "@ember/component", "@ember/object", "@ember/service", "@ember/runloop", "bottomline-execution/config/environment", "bottomline-execution/mixins/app-state", "bottomline-execution/mixins/picture-browser", "bottomline-execution/mixins/transition-to-trip-stop"], function (_exports, _computed, _array, _component, _object, _service, _runloop, _environment, _appState, _pictureBrowser, _transitionToTripStop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_appState.default, _pictureBrowser.default, _transitionToTripStop.default, {
    store: (0, _service.inject)(),
    ajax: (0, _service.inject)(),
    toast: (0, _service.inject)(),
    androidIntentsAtx: (0, _service.inject)(),
    truckSettings: (0, _computed.alias)('appState.truckSettings'),
    tsMarker: null,
    printDocumentStopIds: (0, _array.A)([]),
    f7: (0, _service.inject)('framework7'),
    normalScreenSize: (0, _computed.not)('truckSettings.small_screen_ui'),
    deprecatedSourceForTripStopReport: (0, _object.computed)(function () {
      return this.get('appState.truckSettings.deprecated_source_trip_stop_report');
    }),
    navigationStarted: function navigationStarted() {
      console.log('Navigation started');
    },
    navigationCantStart: function navigationCantStart(e) {
      console.log('Cannot start navigation' + e);
    },
    _startNavigation: function _startNavigation(tripStop) {
      launchnavigator.navigate([tripStop.get('latitude'), tripStop.get('longitude')], (0, _runloop.bind)(this, this.navigationStarted), (0, _runloop.bind)(this, this.navigationCantStart));
    },
    _selectTripStop: function _selectTripStop(tripStop) {
      this.get('appState').setSelectedTripStopId(tripStop.id);
    },
    actions: {
      select: function select(model) {
        this._selectTripStop(model);
      },
      startTripStop: function startTripStop(action, model) {
        this._selectTripStop(model);

        model.set('state', 'started');

        if (action === 'start') {
          if (_environment.default.APP.target === 'atx') {
            this.get('androidIntentsAtx').start(model.get('ActivityCode'));
          }

          model.start();
        }

        if (model.get('isPreAuthorizationRequired')) {
          this._transitionToTripStop(model, 'authorize-site');

          return false;
        }

        this._transitionToTripStop(model);
      },
      editStop: function editStop(model) {
        this._selectTripStop(model);

        model.prepareForEditing();

        this._transitionToTripStop(model);
      },
      startNavigation: function startNavigation(tripStop) {
        this._startNavigation(tripStop);
      },
      getDeliveryTicketsFromTrip: function getDeliveryTicketsFromTrip() {//TODO: should get delivery tickets to get one report of all trip stops
        // Actually this action shouldn't be here
        // Also the action for getDeliveryTickets can live somewhere outside this component and passed on to this one.
      },
      getDocumentForStop: function getDocumentForStop(stop) {
        var _this = this;

        var tripStopId = stop.get('id');

        var flagDocumentOpening = function flagDocumentOpening(opening) {
          _this._flagDocumentOpening(opening, tripStopId);
        };

        var errorOpeningDocument = function errorOpeningDocument(error) {
          _this._errorOpeningDocument(error, tripStopId);
        };

        this.get('store').query('pdf-document', {
          documentType: 'tripStopReport',
          modelId: tripStopId
        }).then(function (documentsList) {
          var documentOpenOptions = {
            signalOpening: flagDocumentOpening,
            showWarning: errorOpeningDocument
          };

          switch (documentsList.length) {
            case 0:
              _this.get('toast').info(_this.get('i18n').t('trip-stop.documents.notfound'));

              break;

            case 1:
              documentsList.get('firstObject').open(documentOpenOptions);
              break;

            default:
              _this._showDocumentSelector(documentsList, documentOpenOptions, tripStopId);

          }
        }).catch(function (error) {
          _this._errorOpeningDocument(error, tripStopId);
        });
      }
    },
    _flagDocumentOpening: function _flagDocumentOpening(opening, tripStopId) {
      if (opening) {
        this.get('printDocumentStopIds').pushObject(tripStopId);
      } else {
        this.get('printDocumentStopIds').removeObject(tripStopId);
      }
    },
    _errorOpeningDocument: function _errorOpeningDocument(error, tripStopId) {
      this.get('toast').error(this.get('i18n').t('trip-stop.documents.error') + ": " + error.message);

      this._flagDocumentOpening(false, tripStopId);
    },
    _showDocumentSelector: function _showDocumentSelector(documentsList, documentOpenOptions, tripStopId) {
      var _this2 = this;

      var openDocument = function openDocument(document) {
        try {
          document.open(documentOpenOptions);
        } catch (error) {
          _this2._errorOpeningDocument(error, tripStopId);
        }
      };

      var documentButtons = documentsList.map(function (document) {
        return {
          text: _this2.get('i18n').t("trip-stop.documents.".concat(document.description), {
            default: document.description
          }),
          onClick: function onClick() {
            openDocument(document);
          }
        };
      });
      this.get('f7').modal({
        text: this.get('i18n').t('trip-stop.documents.dialogtext'),
        verticalButtons: true,
        buttons: documentButtons
      });
    }
  });

  _exports.default = _default;
});