define("bottomline-execution/serializers/bx/order", ["exports", "active-model-adapter", "@ember/object", "@ember/service"], function (_exports, _activeModelAdapter, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    store: (0, _service.inject)(),
    orderRemarkSerializer: (0, _object.computed)(function () {
      return this.get('store').serializerFor('order-remark');
    }),
    mapResponse: function mapResponse(tripStopJson) {
      var orderId = tripStopJson.id;
      var mappedOrderRemarks = this.get('orderRemarkSerializer').mapResponse(orderId, tripStopJson.manual_order_remarks);
      var order = {
        id: orderId,
        type: 'order',
        relationships: {
          order_remarks: {
            data: mappedOrderRemarks.data.map(function (_ref) {
              var type = _ref.type,
                  id = _ref.id;
              return {
                type: type,
                id: id
              };
            })
          }
        }
      };
      return {
        data: order,
        included: mappedOrderRemarks.data
      };
    }
  });

  _exports.default = _default;
});