define("bottomline-execution/serializers/pdf-document", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Serializer = _emberData.default.Serializer;

  var _default = Serializer.extend({
    normalizeResponse: function normalizeResponse(_store, _primaryModelClass, payload, _id, _requestType) {
      var mappedPdfDocuments = payload['documents'].map(function (document) {
        return {
          id: document.id,
          type: 'pdf-document',
          attributes: {
            description: document.description.toLowerCase(),
            documentType: document.documentType,
            modelId: document.modelId
          }
        };
      });
      return {
        data: mappedPdfDocuments
      };
    }
  });

  _exports.default = _default;
});