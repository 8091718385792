define("bottomline-execution/serializers/bx/material-transfer", ["exports", "@ember/object", "@ember/utils", "ember-uuid"], function (_exports, _object, _utils, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var MaterialTransferSerializer = _object.default.extend({
    init: function init(defaultValues) {
      this.targetQuantity = defaultValues.targetQuantity;
    },
    mapResponse: function mapResponse(tripId, materialTransfersJson) {
      var _this = this;

      var includedTripStopTruckStorages = [];
      var mappedMaterialTransfers = materialTransfersJson.map(function (json) {
        json.id = (0, _emberUuid.v4)();

        var tripStopTruckStorages = _this._buildTripStopTruckStorages(json);

        includedTripStopTruckStorages.push.apply(includedTripStopTruckStorages, _toConsumableArray(tripStopTruckStorages));
        return {
          id: json.id,
          type: 'trip-stop',
          attributes: {
            ActivityCode: 'M',
            ConfirmedDepartureDT: json.timestamp,
            state: 'finished',
            TripID: tripId
          },
          relationships: {
            'trip_stop_truck_storages': {
              data: tripStopTruckStorages.map(function (_ref) {
                var type = _ref.type,
                    id = _ref.id;
                return {
                  type: type,
                  id: id
                };
              })
            }
          }
        };
      });
      return {
        data: mappedMaterialTransfers,
        included: includedTripStopTruckStorages
      };
    },
    _buildTripStopTruckStorages: function _buildTripStopTruckStorages(json) {
      var tripStopTruckStorages = [];

      var transferSource = this._buildMaterialTransferTsts(json, 'source', -1 * json.delta_quantity);

      var transferTarget = this._buildMaterialTransferTsts(json, 'target', json.delta_quantity);

      tripStopTruckStorages.push(transferSource, transferTarget);

      if ((0, _utils.isPresent)(json.product_change)) {
        var replacedProduct = this._buildMaterialChangeTsts(json, 'source', -1 * json.product_change.quantity);

        var replacingProduct = this._buildMaterialChangeTsts(json, 'target', json.product_change.quantity);

        tripStopTruckStorages.push(replacedProduct, replacingProduct);
      }

      return tripStopTruckStorages;
    },
    _buildMaterialTransferTsts: function _buildMaterialTransferTsts(json, endPoint, quantity) {
      var vehicleId = json[endPoint + "_vehicle_id"];
      var compartmentNumber = json[endPoint + "_compartment_number"];
      var tsts = {
        id: json.id + '_' + vehicleId + '_' + compartmentNumber,
        type: 'trip-stop-truck-storage',
        attributes: {
          ActivityCode: null,
          MaterialID: json.product_id,
          TripStopID: json.id,
          TruckStorageID: compartmentNumber
        }
      };
      tsts.attributes[this.targetQuantity] = quantity;
      return tsts;
    },
    _buildMaterialChangeTsts: function _buildMaterialChangeTsts(json, endPoint, quantity) {
      var productId = endPoint == 'source' ? json.product_change.product_id : json.product_id;
      var tsts = {
        id: json.id + '_' + productId,
        type: 'trip-stop-truck-storage',
        attributes: {
          ActivityCode: 'M',
          MaterialID: productId,
          TripStopID: json.id,
          TruckStorageID: null
        }
      };
      tsts.attributes[this.targetQuantity] = quantity;
      return tsts;
    }
  });

  var _default = MaterialTransferSerializer;
  _exports.default = _default;
});